<template>
  <div class="w-100">
    <div :class="{'activeTour' : getGuidedTour.step7}" class="view_inner d-flex flex-column">
      <div class="view_center">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span v-if="getCampaignAdd.type !== 'no_cta' "
                class="dotted_circle"></span>

          <span
            v-if="getCampaignAdd.type !== 'no_cta' && getCampaignAdd.type !== 'retargeting' && getCampaignAdd.type !== 'integration'"
            class="dotted_circle"></span>
          <span
            v-if="getCampaignAdd.type !== 'no_cta' && getCampaignAdd.type !== 'retargeting' && getCampaignAdd.type !== 'integration'"
            class="dotted_circle"></span>
          <span
            v-if="getCampaignAdd.type !== 'no_cta' && getCampaignAdd.type !== 'retargeting' && getCampaignAdd.type !== 'integration'"
            class="dotted_circle"></span>
          <span
            v-if="getCampaignAdd.type !== 'no_cta' && getCampaignAdd.type !== 'retargeting' && getCampaignAdd.campaign_type.retargeting"
            class="dotted_circle"></span>
        </div>


        <div
          :class="{'input-field-error': getCampaignValidations.nameValidations.name || getCampaignValidations.nameValidations.nameLength}"
          class="input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">Enter Campaign Name</div>
            <div class="name">
              <div class="validation_input">
                <input type="text" v-model="getCampaignAdd.name"
                       id="name"
                       placeholder="Give your campaign an easy to remember name..."
                       data-cy="camp-name">
              </div>
            </div>
          </div>
        </div>
        <span class="input-error"
              v-if="getCampaignValidations.nameValidations.name">{{ getCampaignValidations.nameMessages.name }}</span>
        <span class="input-error" v-else-if="getCampaignValidations.nameValidations.nameLength">{{
            getCampaignValidations.nameMessages.nameLength
          }}</span>

        <div class="mt-3 mb-2 profile__box input_icon_text align-items-center">
          <div class="w-100 content">
            <div class="url">Custom Domain</div>
            <div class="name">
              <b-dropdown ref="campaigns_dropdown_quick_link" right
                          class="dropdown-menu-right mt-2 default_style_dropdown" :no-caret="true">
                <div class="dropdown_header br-10 rounded_shape d-flex align-items-center" slot="button-content"
                     data-cy="select-camp">
                  <div class="name"><p> {{ selected_domain.url }} </p></div>
                  <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
                </div>
                <ul id="campaign-dropdown-scroll" class="clear inner">
                  <li class="list_item_li"
                      @click.prevent="selectDomain(false), $refs.campaigns_dropdown_quick_link.hide(true)">
                    Select Custom Domain
                  </li>
                  <template v-if="getDomains.items.length > 0">
                    <li class="list_item_li" @click="$refs.campaigns_dropdown_quick_link.visible = false"
                        v-for="(domain, index) in getDomains.items"
                        v-if="domain.connectivity && domain._id !== getSiteDetails.custom_domain_id"
                        @click.prevent="selectDomain(domain), $refs.campaigns_dropdown_quick_link.hide(true)"
                        :key="index" data-cy="camps">
                      <div class="d-flex align-items-center profile__box">
                        <div class="content">
                          <div class="name">
                            {{ domain.url }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </template>
                  <template v-if="!isAnyCustomDomainConnected">
                    <li class="no_text_li">
                      <hr class="mt-0 mb-2">
                      <p>No custom domain connected yet.
                        <router-link :to="{name: 'custom_domains'}" style="    color: #178eea;">Add New</router-link>
                      </p>
                    </li>
                  </template>
                </ul>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="border-top-0 checkbox-list">
          <p class="list-title">Select Campaign Type</p>
          <div class="list-item d-flex align-items-center">
            <p class="text">Shortener</p>
            <div class="checkbox_input_image ml-auto">
              <input disabled checked="checked" type="checkbox" name=""
                     id="shortener_check">
              <label for="shortener_check" class="no_text with_checked_icon"></label>
            </div>
          </div>

          <div class="list-item d-flex align-items-center">
            <p class="text">Retargeting Pixel</p>
            <div class="checkbox_input_image ml-auto">
              <input type="checkbox"
                     name=""
                     v-model="getCampaignAdd.campaign_type.retargeting"
                     id="retargeting_check">
              <label for="retargeting_check" class="no_text with_checked_icon" data-cy="retargeting"></label>
            </div>
          </div>


        </div>
        <div class="checkbox-list">
          <p class="list-title">Widgets</p>
          <div class="list-item d-flex align-items-center">
            <p class="text">Call-To-Action (CTA)</p>
            <div class="radio_input_image ml-auto">
              <input type="radio" :checked="isCampaignActive('cta')"
                     @click="changeCampaignChoice('cta')"
                     name="" id="cta_check_true">
              <label for="cta_check_true" class="no_text" data-cy="cta"></label>
            </div>
          </div>

          <div class="list-item d-flex align-items-center">
            <p class="text">3rd Party / Custom Widgets</p>
            <div class="radio_input_image ml-auto">
              <input type="radio" :checked="isCampaignActive('third_party')"
                     @click="changeCampaignChoice('third_party')"
                     name="" id="third_party_check" data-cy="custom-widget">
              <label for="third_party_check" class="no_text"></label>
            </div>
          </div>


        </div>

      </div>
      <div class="view_bottom">
        <div v-if="getGuidedTour.step7" class="view_bottom_inner text-center">
          <button class="btn---cta light-blue btn-round">Go Back</button>
          <button class="btn---cta btn-blue btn-round">Next</button>
        </div>

        <div v-else class="view_bottom_inner text-center">
          <button v-if="!getCampaignLoaders.store" @click="$router.push({ 'name': 'create' })"
                  class="btn---cta  light-blue btn-round">
            <span>Go Back</span>
          </button>

          <template v-if="getCampaignLoaders.store && getCampaignAdd.type === 'no_cta'">
            <button disabled="true" class="btn---cta btn-blue btn--loader white--loader loader--right btn-round">
              <span>Saving Campaign</span>
              <clip-loader :size="size" :color="color"></clip-loader>
            </button>
          </template>
          <template v-else>
            <!-- only shortener -->
            <template v-if="getCampaignAdd.type === 'no_cta'">
              <button @click.prevent="storeCampaign()"
                      class="btn---cta btn-blue btn-round">
                <span>{{ getCampaignButtonText() }}</span>
              </button>
            </template>

            <!-- third-party widgets and retargeting option selected-->
            <template v-else-if="getCampaignAdd.type === 'integration' && getCampaignAdd.campaign_type.retargeting">
              <button @click.prevent="campaignTypeValidation('retargeting')" class="btn---cta btn-blue btn-round">
                <span>Next</span>
              </button>
            </template>
            <!-- if the type selected is of integration and user have not selected the retargeting option.-->

            <button
              v-else-if="getCampaignAdd.type === 'integration' && !getCampaignAdd.campaign_type.retargeting"
              @click.prevent="campaignTypeValidation('third_party_integration')"
              class="btn---cta btn-blue btn-round">
              <span>Next</span>
            </button>

            <!-- if the type selected is of retargeting  > change to the message if applicable -->
            <button
              v-else-if="getCampaignAdd.type === 'retargeting'"
              @click.prevent="campaignTypeValidation('retargeting')"
              class="btn---cta btn-blue btn-round">
              <span>Next</span>
            </button>

            <!-- if the type selected is of call to action -->
            <button v-else @click.prevent="campaignTypeValidation('call_to_action_type')"
                    class="btn---cta btn-blue btn-round">
              <span>Next</span>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {mapActions, mapGetters} from 'vuex'

import Dropdown from '@/ui/Dropdown.vue'
import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import {campaignTypes} from '@/state/modules/mutation-types'
import EventBus from "@/state/event-bus";

export default (
  {
    data () {
      return {
        size: '14px',
        color: '#fff',
        selected_domain: {}
      }
    },
    async created () {
      if (!this.getCampaignAdd.brand_id) return this.$router.push({name: 'create'})
      await this.fetchWhitelabelAppSettings()
      await this.configureDefaultDomain()

      const domain = this.getDomains.items.find(item => item._id === this.getCampaignAdd.domainId)
      if (this.getSiteDetails.isWhiteLabel && !this.getCampaignAdd._id && this.getDomains.items.length == 1) {
        this.selected_domain.addNew = true
        this.selected_domain.url = 'Select Custom domain'
        return
      }

      if (this.getSiteDetails.isWhiteLabel && this.getCampaignAdd._id && this.getCampaignAdd.domainId == this.getSiteDetails.custom_domain_id) {
        this.selected_domain.addNew = true
        this.selected_domain.url = 'Select Custom domain'
        return
      }

      this.selected_domain.addNew = false
      // selecting the custom domain
      this.selected_domain.url = domain ? domain.url : 'Select Custom domain'
      this.$forceUpdate()
    },
    components: {
      Dropdown,
      GuidedTour
    },
    computed: {
      ...mapGetters([
        'getDomains',
        'getCampaignAdd',
        'getCampaignLoaders',
        'getCampaignValidations'
      ]),
      /**
       * Checks if any custom domain is connected based on the connectivity property of items in the getDomains array.
       * @returns {boolean} True if at least one custom domain is connected, otherwise false.
       */
      isAnyCustomDomainConnected() {
        if (this.getDomains.items && this.getDomains.items.length) {
          return this.getDomains.items.some(item => item.connectivity === true);
        }
        return false;
      }
    },
    methods: {
      // checking the campaign type depending on the triggers
      ...mapActions(['fetchWhitelabelAppSettings']),
      /**
       * campaign custom domain selection method
       * @param domain
       */
      selectDomain (domain) {
        // for no domain selection we are resetting it to default
        if (!domain) {
          this.selected_domain.url = 'Select Custom Domain'
          this.getCampaignAdd.domainId = ''
          this.$forceUpdate()
          return
        }

        this.getCampaignAdd.domainId = domain._id
        this.selected_domain.url = domain.url
        this.selected_domain.addNew = false
        this.$forceUpdate()
      },
      checkCampaignType () {
        let campaignType = this.getCampaignAdd.campaign_type
        // if it has the type CTA, set the button of type
        if (campaignType.cta) {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'button')
        } else if (!campaignType.cta && !campaignType.third_party && campaignType.retargeting) {
          // if only retargeting is enabled
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'retargeting')
        } else if (campaignType.third_party) {
          // if only third party is enabled
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'integration')
        } else {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'no_cta')
        }
      },
      // check if the user has selected the CTA or third party widget option, we are using
      // different fields, therefore v-model cannot be applied
      isCampaignActive (type) {
        let campaignTypes = this.getCampaignAdd.campaign_type
        return (campaignTypes[type] === true) ? 'checked' : false
      },
      // Changing the value of the radio button on the button click event
      changeCampaignChoice (type) {
        this.getCampaignAdd.campaign_type[type] = !this.getCampaignAdd.campaign_type[type]
        if (type === 'cta') {
          this.getCampaignAdd.script_ids = []
        }
      },
      configureDefaultDomain () {
        let campaign = this.getCampaignAdd
        if (!campaign._id && !campaign.domainId) {
          let domain = this.getDomains.items.find(item => item['default'] === true && item['connectivity'] === true && item._id !== this.getSiteDetails.custom_domain_id)
          if (domain) {
            this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_CUSTOM_DOMAIN, domain._id)
          }

        }
      }
    },
    watch: {
      'getCampaignAdd.campaign_type.cta' (value) {
        // watcher to set the values for the preview where we are showing it.
        if (value) {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE_THIRD_PARTY_VALUE, false)
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'button')
        } else {
          this.checkCampaignType()
        }
      },
      'getCampaignAdd.campaign_type.third_party' (value) {
        // watcher to set the values for the preview where we are showing it.
        if (value) {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_CTA_VALUE, false)
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'integration')
        } else {
          this.checkCampaignType()
        }
      },
      'getCampaignAdd.campaign_type.retargeting' (value) {
        // watcher to set the values for the preview where we are showing it.
        if (value) {
          // if the third party type has been set

          if (this.getCampaignAdd.campaign_type.third_party) {
            this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'integration')
          } else if (!this.getCampaignAdd.campaign_type.cta) {
            this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_TYPE, 'retargeting')
          }
        } else {
          this.checkCampaignType()
        }
      },
      'getCampaignAdd.name' (value) {
        if (value && value.length > 0) {
          this.getCampaignValidations.nameValidations.name = false
          this.getCampaignValidations.nameValidations.nameLength = !this.maxLength(value, 100)
        }
      }

    }
  })
</script>
